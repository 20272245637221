.p-container {
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  width: $width--base;

  &:after {
    clear: both;
    content: ' ';
    display: table;
  }
}
