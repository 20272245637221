.scroll-down {
  animation: slideInDown 1s ease-out 1s 1 forwards;
  height: 3.3rem;
  left: 50%;
  margin-left: -0.8rem;
  margin-top: -1.6rem;
  opacity: 0;
  position: absolute;
  top: calc(50% + 9rem);
  width: 1.7rem;

  &__svg {
    left: 0;
    overflow: visible;
    position: absolute;
    top: 0;
  }

  &__arrow {
    animation: moveBackAndForth 1.5s ease-in 2s infinite;
  }
}

@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(-1rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes moveBackAndForth {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(1rem);
  }
}
