.link {
  color: $color-link-base;
  fill: $color-link-base;
  font-size: $font--md;
  font-weight: $font--normal;
  text-decoration: none;

  @include on-event() {
    color: $color-link-accent;
    fill: $color-link-accent;
  }
}
