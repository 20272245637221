.peculiarities {
  background-color: rgba(255, 255, 255, 0.5);
  border: 0.4rem solid $color-brand;
  height: 27rem;
  margin: 5rem auto 3rem;
  overflow: hidden;
  padding: 2.5rem;
  position: relative;
  width: 62rem;

  &__list {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    text-align: left;
    width: 80%;
  }

  &__list-item {
    margin-bottom: 1.5rem;
    padding-left: 2rem;
    position: relative;
    white-space: nowrap;

    &:before {
      background-color: $color-brand-dark;
      border-radius: 50%;
      content: '';
      height: 0.6rem;
      left: 0;
      margin-top: -0.3rem;
      position: absolute;
      top: 50%;
      width: 0.6rem;
    }
  }

  & .heading {
    font-family: $font-stack-headings;
    font-size: $font--xxl;
    margin: 0 0 2.5rem;
    text-align: center;
  }

  & .text {
    font-style: italic;
    line-height: $lh--base-plus;
    margin: 0;
    padding: 0;
  }

}
