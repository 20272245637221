.section {
  padding-top: 6.5rem;

  &__heading {
    font-family: $font-stack-headings;
    font-size: $font--section-heading;
    margin: 5rem 0 2.5rem;
    text-align: center;
  }
}
