.list {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;

  &__item {
    margin-bottom: 1.5rem;
    padding-left: 2rem;
    position: relative;

    &:before {
      background-color: $color-brand-dark;
      border-radius: 50%;
      content: '';
      height: 0.6rem;
      left: 0;
      margin-top: -0.3rem;
      position: absolute;
      top: 50%;
      width: 0.6rem;
    }
  }
}
