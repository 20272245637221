.about-company-info {
  margin-bottom: 5rem;
  position: relative;

  & > .text {
    float: left;
    width: 62rem;
  }

  & > .image {
    height: auto;
    width: calc(100% - 62rem);
  }
}
