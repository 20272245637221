/**
 * Font families
 * @type List
 */
$font-stack-base: HelveticaNeueCyr, Helvetica, Arial sans-serif !default;
$font-stack-headings: 'PT Serif', Helvetica, Arial sans-serif !default;

/**
 * Font-sizes
 * @type Length
 */

/**
 * 1. Root font-size is set to 62.5% so that all the REM measurements throughout
 *    the site/application are based on 10px sizing. So basically 1.5rem = 15px
 */

$font--root: 62.5% !default; /* 1 */
$font--xs: 1rem !default;
$font--sm: 1.2rem !default;
$font--md: 1.4rem !default;
$font--xm: 1.6rem !default;
$font--lg: 1.8rem !default;
$font--xl: 2rem !default;
$font--xxl: 2.2rem !default;

// Project specific font sizes
$font--main-heading: 4.2rem !default;
$font--section-heading: 3.6rem !default;
$font--contacts-title: 2.6rem !default;
$font--modals-title: 2.4rem !default;

/// Font weights
/// @type Length
$font--light: 300 !default;
$font--normal: 400 !default;
$font--semi-bold: 500 !default;
$font--bold: 700 !default;

/**
 * Colors
 * @type Color
 */
$color-brand: #f7dc00 !default;
$color-brand-dark: #edc800 !default;

/* text */
$color-text-base: #2b2b2b !default;

/* links */
$color-link-base: $color-text-base !default;
$color-link-accent: $color-brand !default;
/* background */
$color-bg-page: #fff !default;
$color-bg-wrapper: transparent !default;
$color-bg-gray: #f4f4f4 !default;
/* borders */
$color-border-base: #f6e37f !default;

/**
 * Default line-height
 * @type length
 */
$lh--base: 1.428571429; /* 20/14 */
$lh--base-plus: 1.78571429; /* 25/15 */

/**
 * Base grid width
 * @type Length
 */
$width--base: 96rem !default;

/**
 * Relative or absolute URL where all assets are served from
 * @type String
 * @example scss - When using a CDN
 * $base-url: "http://cdn.example.com/assets/";
 */
$base-url: "./" !default;
