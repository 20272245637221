.p-header {
  border-top: 0.4rem solid $color-brand-dark;
  height: 16rem;
  left: 0;
  min-width: $width--base;
  padding-top: 7.6rem;
  position: absolute;
  top: 0;
  width: 100%;
  transition: all 0.3s ease-out;
  z-index: 100;

  .page.inner &.is-sticky,
  &.is-sticky {
    background-color: rgba(255, 255, 255, 0.95);
    background-image: none;
    box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.2);
    height: 6.4rem;
    padding-top: 2.1rem;
    position: fixed;

    .main-menu__contact {
      height: 0.1rem;
      margin: -0.1rem;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-5rem);
      z-index: -1;
    }

    .main-logo {
      height: 3.8rem;
      margin: -1.5rem 3rem 0;
      width: 3.2rem;
    }

    .main-logo__img {
      transform: translate(-0.7rem, -3.2rem);
      width: 4.6rem;
    }
  }

  @include when-inside('.page.inner') {
    background: url('../img/inner-page-header-bg.jpg') 50% 0 no-repeat;
    background-size: cover;
    box-shadow: 0 0 2rem 0.1rem rgba(0, 0, 0, 0.3);
    height: 17rem;
    padding-top: 7.2rem;
  }
}
