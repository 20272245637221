.main-logo {
  display: block;
  height: 11.5rem;
  margin: -4.1rem 6rem 0;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-out;
  width: 12rem;

  &__img {
    height: 11.5rem;
    left: 0;
    position: absolute;
    transform: translate(0, 0);
    top: 0;
    width: 12rem;
    transition: all 0.3s ease-out;
  }

  @include when-inside('.page.inner') {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
}
