.main-heading {
  font-family: $font-stack-headings;
  font-size: $font--main-heading;
  font-weight: $font--bold;
  left: 50%;
  line-height: 1.2;
  margin: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;

  &:before,
  &:after {
    background-color: $color-brand-dark;
    border-radius: 50%;
    content: '';
    display: block;
    height: 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
  }

  &:before {
    left: -5rem;
  }

  &:after {
    right: -5rem;
  }
}
