/**
 * 1. Set up a decent box model on the root element
 */

/*html*/.root {
  background-color: $color-bg-page;
  box-sizing: border-box; /* 1 */
  font-size: $font--root;
  margin: 0;
  min-height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;

  /*
   * Helps to fix wrong calculations of rem units
   * when the html font-size is set in percentages (62.5%)
   */
  &.ie {
    font-size: 10px;
  }
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */

*, *::before, *::after {
  box-sizing: inherit;
}

*:hover,
*:active,
*:focus {
  outline: none;
}

/*body*/.page {
  background-color: $color-bg-page;
  color: $color-text-base;
  font-family: $font-stack-base;
  font-size: $font--md;
  font-style: normal;
  font-weight: $font--normal;
  line-height: $lh--base;
  margin: 0;
  padding: 0;

  &.inner {
    margin-bottom: 6rem; /* footer's height */
  }
}
