.services-list {
  list-style: none;
  margin: 0 0 7rem;
  padding: 0;

  &__item {
    height: 22rem;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    & > .media {
      background-color: $color-bg-gray;
      height: 100%;
      padding: 2.5rem 3rem 4rem;
    }

    & .media__heading {
      font-size: $font--lg;
      margin-bottom: 1rem;
      padding-top: 1rem;
      text-transform: uppercase;
    }

    & .media__text {
      font-size: $font--xm;
      line-height: $lh--base-plus;
    }

    & .media__icon {
      border: 0.2rem solid #ccc;
      fill: #ccc;
      display: block;
      border-radius: 50%;
      height: 8rem;
      width: 8rem;
    }

    & .delimeter {
      stroke: #b8b8b8;
      height: 0.4rem;
      margin-bottom: 1.5rem;
      width: 6rem;
    }
  }

  &__item:nth-child(odd) {
    & > .media {
      float: left;
      margin-left: -1rem;
      width: 49rem;
    }

    & > .services-list__img {
      float: left;
      width: 46rem;
    }
  }

  &__item:nth-child(even) {
    & > .media {
      float: right;
      width: 46rem;
    }

    & > .services-list__img {
      float: left;
      margin-left: -1rem;
      width: 49rem;
    }
  }

  &__item:hover {
    cursor: pointer;

    & .media__icon {
      background-color: $color-brand;
      border-color: $color-brand;
      fill: #fff;
    }
  }

  &__item-more {
    display: inline-block;
    font-weight: $font--bold;
    margin-top: 0.5rem;
    transition: color 0.2s ease-out;

    .services-list__item:hover & {
      color: $color-brand-dark;
    }
  }

}
