/*
@font-face {
  font-family: 'Font Name';
  src: local('Font Name');
  src: url('../fonts/Font-Name.woff2') format('woff2'),
       url('../fonts/Font-Name.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
*/

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: local('HelveticaNeueCyr Roman');
  src: url('../fonts/HelveticaNeueCyr-Roman-webfont.woff2') format('woff2'),
       url('../fonts/HelveticaNeueCyr-Roman-webfont.woff') format('woff');
  font-weight: $font--normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: local('HelveticaNeueCyr Bold');
  src: url('../fonts/HelveticaNeueCyr-Italic-webfont.woff2') format('woff2'),
  url('../fonts/HelveticaNeueCyr-Italic-webfont.woff') format('woff');
  font-weight: $font--normal;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: local('HelveticaNeueCyr Bold');
  src: url('../fonts/HelveticaNeueCyr-Bold-webfont.woff2') format('woff2'),
       url('../fonts/HelveticaNeueCyr-Bold-webfont.woff') format('woff');
  font-weight: $font--bold;
  font-style: normal;
}
