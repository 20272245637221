.about-company {
  background: #fff url('../img/about-company-bg--full.jpg') 50% 0 no-repeat;
  background-size: cover;
  min-width: $width--base;

  & .text {
    line-height: $lh--base-plus;
    text-align: center;
    padding: 0 4rem;
  }
}
