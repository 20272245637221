.main-menu {
  font-size: 0;
  font-weight: $font--bold;
  line-height: 1;
  text-align: center;
  width: 100%;
  white-space: nowrap;

  &__left,
  &__center,
  &__right {
    display: inline-block;
    vertical-align: middle;
  }

  &__left {
    text-align: right;
  }

  &__right {
    text-align: left;
  }

  &__contact {
    border-bottom: 0.1rem solid transparent;
    color: inherit;
    display: inline-block;
    font-size: $font--md;
    margin-bottom: 3rem;
    position: relative;
    text-decoration: none;
    vertical-align: middle;

    &:hover,
    &:focus {
      border-bottom-color: $color-border-base;
    }

    &:active {
      color: $color-brand-dark;
    }

    & > .svg-icon {
      fill: $color-brand-dark;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    @include when-inside('.page.inner') {
      margin-bottom: 2.5rem;
    }

    &--phone {
      margin-left: 2.8rem;

      & > .svg-icon {
        height: 1.8rem;
        left: -2.8rem;
        width: 1.8rem;
      }

      @include when-inside('.page.inner') {
        margin-right: 1.5rem;
      }
    }

    &--email {
      margin-left: 3rem;

      & > .svg-icon {
        height: 1.4rem;
        left: -3rem;
        width: 2rem;
      }

      @include when-inside('.page.inner') {
        margin-left: 4.5rem;
      }
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    display: inline-block;
    font-size: 1.5rem;
    transition: all 0.2s ease-out;
    vertical-align: middle;

    &:not(:last-child) {
      margin-right: 3.5rem;
    }

    @include when-inside('.page.inner') {
      height: 2.5rem;
      line-height: 2.5rem;
      padding: 0 1.5rem;

      &:hover,
      &.is-active {
        background: $color-brand-dark;
        border-radius: 2.5rem;
      }
    }
  }

  &__link {
    color: inherit;
    text-decoration: none;

    .main-menu__list-item:hover > &,
    .main-menu__list-item.active > & {
      color: $color-brand-dark;
    }

    &:active {
      color: inherit;
    }

    .page.inner .main-menu__list-item:hover > &,
    .page.inner .main-menu__list-item.is-active > & {
      color: inherit;
    }
  }
}
