.features {
  bottom: -6rem;
  font-size: 0;
  left: 50%;
  list-style: none;
  margin: 0;
  padding: 0 1rem;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;

  &__item {
    background-color: #fff;
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.1);
    display: inline-block;
    font-size: $font--lg;
    height: 12rem;
    position: relative;
    text-align: left;
    overflow: hidden;
    padding: 3.5rem 0 0 9rem;
    vertical-align: middle;
    width: 30rem;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    & > .svg-icon {
      position: absolute;
    }

    &:first-child > .svg-icon {
      height: 6.9rem;
      left: 1.9rem;
      top: 2.4rem;
      width: 5.4rem;
    }

    &:nth-child(2) > .svg-icon {
      height: 4rem;
      left: 1rem;
      top: 3.9rem;
      width: 7rem;
    }

    &:last-child > .svg-icon {
      height: 6rem;
      left: 1.1rem;
      top: 3rem;
      width: 5.6rem;
    }
  }
}
