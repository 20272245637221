.media {
  overflow: hidden;
  zoom: 1;

  &__img {
    padding-right: 1rem;
    display: table-cell;
    vertical-align: top;
  }

  &__body {
    display: table-cell;
    vertical-align: top;
    overflow: hidden;
    zoom: 1;
  }

  &__heading {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: $font--bold;
    margin: 0;

    & > .link {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      text-decoration: none;
    }
  }

  &__text {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
  }
}
