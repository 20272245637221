h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-stack-base;
  font-weight: $font--bold;
  line-height: $lh--base;
}

b,
strong {
  font-family: $font-stack-base;
  font-weight: $font--bold;
  line-height: $lh--base;
}

i,
em {
  font-family: $font-stack-base;
  font-style: italic;
  font-weight: $font--normal;
  line-height: $lh--base;
}

p {
  font-family: $font-stack-base;
  font-weight: $font--normal;
  line-height: $lh--base;
}

a, img {
  border: none;
  outline: none;
}

a {
  transition: all 0.2s ease-out;
}

