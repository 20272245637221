.intro {
  padding-top: 0;

  &__wrapper {
    background: #fff url('../img/intro-bg--full.jpg') 50% 0 no-repeat;
    background-size: cover;
    height: calc(100% - 10rem);
    margin-bottom: 10rem;
    min-width: $width--base;

    & > .p-container {
      height: 100%;
    }
  }

}
