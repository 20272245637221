.p-footer {
  background-color: #fff;
  height: 6rem;
  overflow: hidden;
  padding-top: 1rem;

  &__copyright {
    float: left;
  }

  &__created {
    float: right;

    & > .link {
      border-bottom: 0.1rem solid $color-border-base;
      display: inline-block;
      font-weight: $font--bold;
      text-decoration: none;
      vertical-align: middle;

      &:hover,
      &:focus {
        border-bottom-color: transparent;
        color: $color-text-base;
      }
    }
  }

  @include when-inside('.page.inner') {
    border-top: 0.1rem solid $color-border-base;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
