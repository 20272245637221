.heading {
  color: $color-text-base;
  font-family: $font-stack-base;
  font-weight: $font--bold;
  margin: 0 0 2rem;
  text-align: center;

  @include when-inside('.page.inner') {
    text-align: left;
  }
}
