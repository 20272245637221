.licences-and-recommendations {

  .licences-slider,
  .recommendations-slider {
    position: relative;
  }

  .slick-slider {
    margin: 0 -1rem 6rem;

    .slick-track {
      font-size: 0;
    }

    &__item {
      border: 0.4rem solid $color-brand-dark;
      display: inline-block;
      cursor: pointer;
      height: 22rem;
      margin: 0 1rem;
      padding: 2.5rem 4.5rem;
      vertical-align: middle;
      width: 46rem !important;
    }

    /* image shelf */
    &__img-wrapper {
      background: url('../img/image-shelf-bg.png') 0 100% no-repeat;
      display: inline-block;
      height: 15.2rem;
      margin-right: 3rem;
      position: relative;
      vertical-align: middle;
      width: 13rem;
    }

    &__img {
      display: block;
      height: 13rem;
      left: 2rem;
      position: absolute;
      top: 0;
      width: 9rem;
    }

    &__text {
      display: inline-block;
      font-size: $font--xm;
      line-height: $lh--base-plus;
      margin: 0;
      max-width: 18rem;
      vertical-align: middle;

      & > .highlight {
        background: $color-bg-gray;
        box-shadow: 0.5rem 0 0 $color-bg-gray, -0.5rem 0 0 $color-bg-gray;
        padding: 0.2rem 0;
        margin: 0 0.5rem;
        white-space: nowrap;
      }
    }

    &__prev.btn,
    &__next.btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        fill: $color-brand-dark;
      }

      &:active {
        fill: inherit;
      }
    }

    &__prev {
      left: -9rem;
    }

    &__next {
      right: -9rem;
    }

    &__prev.slick-disabled,
    &__next.slick-disabled,
    &__prev.slick-disabled:hover,
    &__next.slick-disabled:hover {
      cursor: default;
      fill: inherit;
      opacity: .20;
    }
  }

  &__modal {
    min-width: 35rem;
    max-width: 86rem;
    width: 30%;

    .remodal__content > .image {
      height: auto;
      margin: 0 auto;
      width: 100%;
    }

    @at-root {
      @media only screen and (min-height: 700px) {
        & {
          width: 35%;
        }
      }

      @media only screen and (min-height: 800px) {
        & {
          width: 40%;
        }
      }

      @media only screen and (min-width: 1280px) {
        & {
          max-width: 55rem;
        }
      }
    }
  }
}
