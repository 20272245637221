.contacts {
  background: $color-bg-gray url("../img/contacts-map-bg.jpg") 50% 0 no-repeat;
  background-size: 192rem 44rem;
  padding-top: 0;
  position: relative;

  & > .p-container {
    height: 44rem;
  }

  &__map-wrapper {
    height: 44rem;
    left: 0;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__card {
    background-color: rgba(216, 186, 25, 0.9);
    bottom: 13rem;
    height: 22rem;
    left: 1rem;
    overflow: hidden;
    padding: 1.5rem 2rem;
    position: absolute;
    /**
     * 1.Fix for Safari:
     * Prevents the card placed over Google maps
     * from flickering (recurring disappearing)
     */
    -webkit-transform: translateZ(0); /* 1 */
    width: 38rem;
    z-index: 10;

    & > .text {
      margin-bottom: 1rem;
    }
  }

  &__phone,
  &__email {
    color: $color-text-base;
    font-style: normal;
  }

  &__phone {
    text-decoration: none;
  }

  &__email {
    font-weight: $font--semi-bold;
  }

  &__card-title {
    font-family: $font-stack-headings;
    font-size: $font--contacts-title;
    font-weight: $font--bold;
    margin: 0 0 1rem;
  }
}
