.portfolio {
  background-color: $color-bg-gray;

  &__masonry {
    margin-bottom: 4.5rem;
    position: relative;
  }

  &__item {
    float: left;
    position: relative;
    margin-bottom: 2rem;
    overflow: hidden;
    width: 30rem;

    & > .porfolio__preview-img {
      height: 100%;
      display: block;
      width: auto;
    }

    &:after {
      background-color: rgba(216, 186, 25, 0.8);
      bottom: 0;
      content: attr(data-text);
      display: block;
      font-weight: $font--bold;
      left: 0;
      min-height: 5rem;
      padding: 1.5rem;
      position: absolute;
      transform: translateY(15rem);
      transition: all 0.3s ease-out;
      width: 100%;
    }

    &:hover {
      cursor: pointer;

      &:after {
        transform: translateY(0);
      }
    }

  }

  &__modal {
    width: 88rem;

    & > .remodal__content {
      padding-bottom: 4rem;
    }

    .time {
      background-color: rgba(237, 200, 0, 0.8);
      display: inline-block;
      font-weight: $font--bold;
      height: 2.6rem;
      line-height: 2.6rem;
      margin-bottom: 2rem;
      padding: 0 2rem;
      text-align: left;
    }

    .heading {
      font-family: $font-stack-headings;
      font-size: $font--lg;
      margin: 0 0 2.5rem;
      text-align: left;
    }

    .portfolio__item-prev,
    .portfolio__item-next {
      border-bottom: 0.1rem solid $color-border-base;
      bottom: 2rem;
      display: inline-block;
      fill: $color-brand-dark;
      font-size: $font--md;
      font-weight: $font--bold;
      margin: 0;
      overflow: visible;
      padding: 0;
      position: absolute;
      text-decoration: none;
      transition: all 0.2s ease-out;
      vertical-align: middle;

      & > .svg-icon {
        height: 1.2rem;
        margin-top: -0.5rem;
        position: absolute;
        top: 50%;
        width: 0.7rem;
      }

      &:hover,
      &:focus {
        border-bottom-color: transparent;
        color: $color-text-base;
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus {
        border-bottom: 0.1rem solid $color-border-base;
        cursor: default;
        color: $color-text-base;
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .portfolio__item-prev {
      left: 2rem;
      margin-left: 2rem;

      & > .svg-icon {
        left: -2rem;
      }
    }

    .portfolio__item-next {
      right: 2rem;
      margin-right: 2rem;

      & > .svg-icon {
        right: -2rem;
      }
    }
  }

  &__item-slick {
    height: 42rem;
    margin-bottom: 2.5rem;
    position: relative;
  }

  &__item-slick--lrg {
    height: 42rem;
    left: 0;
    overflow-y: hidden;
    position: absolute;
    top: 0;
    width: 72rem;

    .image {
      height: 42rem;
      width: 72rem;
    }
  }

  &__item-slick--thumb {
    height: 41rem;
    right: -1rem;
    position: absolute;
    top: 1.8rem;
    width: 12rem;
  }

  &__item-slick-prev.btn,
  &__item-slick-next.btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
    margin-left: -0.3rem;
    z-index: 10;

    &:hover {
      fill: $color-brand-dark;
    }

    &:active {
      fill: inherit;
    }
  }

  &__item-slick-prev {
    top: -1.1rem;
  }

  &__item-slick-next {
    bottom: -1.5rem;
  }

  &__item-slick-prev.slick-disabled,
  &__item-slick-next.slick-disabled,
  &__item-slick-prev.slick-disabled:hover,
  &__item-slick-next.slick-disabled:hover {
    cursor: default;
    fill: inherit;
    opacity: .20;
    pointer-events: none;
  }

  &__item-slick-thumbs {
    height: 40rem;
    margin: -1rem 0;
    width: 12rem;

    .image-wrapper {
      height: 8rem;
      margin: 1rem 0;
      width: 11rem;

      .image {
        display: block;
        height: 8rem;
        margin: 0 auto;
        width: 10rem;
      }

      &:hover {
        .image {
          box-shadow: 0 0 1.4rem 0.1rem rgba(0, 0, 0, 0.3);
          cursor: pointer;
        }
      }

      &.slick-current {
        .image {
          border: 0.4rem solid $color-brand-dark;
          box-shadow: 0 0 1.4rem 0.1rem rgba(0, 0, 0, 0.3);
          cursor: pointer;
        }
      }
    }

    &.slick-loading {
      .slick-list {
        background: #fff url('../img/slick-ajax-loader.gif') center center no-repeat;
      }
    }
  }
}
