.btn {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
}
